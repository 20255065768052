/**
 * 获取随机字符串
 * @param length
 */
export function generateRandomString(length: number) {
    const charset = "abcdefghijklmnopqrstuvwxyz";
    const charsetNum = "abcdefghijklmnopqrstuvwxyz0123456789";
    const values = new Uint32Array(length);
    window.crypto.getRandomValues(values);
    let str = '';
    for (let i = 0; i < length; i++) {
        if (i === 0) {
            str += charset[values[i] % charset.length];
        } else {
            str += charsetNum[values[i] % charsetNum.length];
        }
    }
    return str;
}

/**
 * @description: 对象深拷贝
 * @date: 2023-10-30 14:57:49
 * @params:
 */
export function deepClone(obj:any){
    if(obj === null) return null;
    const clone:any = obj instanceof Array ? [] : {};
    const arr:any = Object.getOwnPropertyNames(obj);
    arr.forEach((key:any) => clone[key] = typeof obj[key] === "object" ? deepClone(obj[key]) : obj[key]);
    return clone;
}
/**
 * @description: 简单防抖
 * @date: 2023-11-16 14:30:27
 * @params:
 */

export function debounceFun(fn:any,delay:number){
    let timer:any = null;
    return function(...args:any[]){
        if(!timer){
            timer = setTimeout(() => {
                fn.apply(null,...args);
                timer = null;
            }, delay);
        }
    }

}


/**
 * @description: 数组去空
 * @date: 2023-11-17 11:11:01
 * @params:
 */
export function removeEmpty(arr:any){
    return arr.filter((item:any) => {
        return item!== null && item!== undefined && item!== '';
    })
}
