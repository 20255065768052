import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ClassAutomation from '../views/index/class-automation.vue'
import HomeView from '../views/visual-operation/visual-operation.vue'
import HelpPage from '../views/help/help-page.vue'
import GiveReward from '../views/give-reward/give-reward.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/classAutomation',
    name: 'classAutomation',
    component: ClassAutomation
  },
  {
    path: '/helpPage',
    name: 'helpPage',
    component: HelpPage
  },
  {
    path: '/giveReward',
    name: 'giveReward',
    component: GiveReward
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
