export class DomObj{
    domName: string;
    domRetract: number;
    domKey: string;
    domType: number; // 0-开始标签，1-文本内容，9-结束
    domClassName: string;
    showText: string;
    constructor(domName = '' ,domRetract = 0,domKey = '',domType = 0,showText = '',domClassName = '') {
        this.domName = domName;
        this.domRetract = domRetract;
        this.domKey = domKey;
        this.domType = domType;
        this.domClassName = domClassName;
        this.showText = showText;
    }
}
