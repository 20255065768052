import axios from 'axios';
import { ElLoading } from 'element-plus'
import {Md5} from "ts-md5";

/**
 * @description: 文本翻译通用工具
 * @date: 2023-11-03 15:54:36
 * @params: needStr 需要翻译单独词语，如果type为2则为需要翻译的词语集合以‘,’分割
 */

export function getEnglishListCommen(needStr:string) {
    const loadingInstance = ElLoading.service({text:'正在操作中'});
    const appid = '20230826001793962';
    const key = 'HoZ2gGKUfn0mEAXXpmCJ';
    const salt = new Date().getTime();
    const query = needStr;
    const from = 'zh';
    const to = 'en';
    const str1 = appid + query + salt + 'it' + key;
    // 定义MD5对象
    const md5: any = new Md5()
    md5.appendStr(str1)
    const sign = md5.end()
    const instance = axios.create({
        baseURL: '',
        timeout: 1000,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
    const url = `/api/api/trans/vip/fieldtranslate`;
    return new Promise((resolve,reject)=>{
        instance.post(url, {
            q: query,
            from:from,
            to:to,
            appid:appid,
            salt:salt,
            domain:'it',
            sign:sign
        }).then((res)=>{
            resolve(res);
        }).catch((res)=>{
            reject(res);
        }).finally(()=>{
            loadingInstance.close();
        })
    })
}
